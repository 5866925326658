.carousel {
    position: relative
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner > .item {
    position: relative;
    display: none;
    -webkit-transition: .6s ease-in-out left;
    -o-transition: .6s ease-in-out left;
    transition: .6s ease-in-out left;
    padding-bottom: 0px;
}

.carousel-inner > .item > a > img, .carousel-inner > .item > img {
    line-height: 1
}

@media all and (transform-3d),(-webkit-transform-3d) {
    .carousel-inner > .item {
        -webkit-transition: -webkit-transform .6s ease-in-out;
        -o-transition: -o-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px
    }

    .carousel-inner > .item.active.right, .carousel-inner > .item.next {
        left: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    .carousel-inner > .item.active.left, .carousel-inner > .item.prev {
        left: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    .carousel-inner > .item.active, .carousel-inner > .item.next.left, .carousel-inner > .item.prev.right {
        left: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.carousel-inner > .active, .carousel-inner > .next, .carousel-inner > .prev {
    display: block
}

.carousel-inner > .active {
    left: 0
}

.carousel-inner > .next, .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%
}

.carousel-inner > .next {
    left: 100%
}

.carousel-inner > .prev {
    left: -100%
}

.carousel-inner > .next.left, .carousel-inner > .prev.right {
    left: 0
}

.carousel-inner > .active.left {
    left: -100%
}

.carousel-inner > .active.right {
    left: 100%
}

.carousel-control {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10%;
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    background-color: rgba(0, 0, 0, 0);
    filter: alpha(opacity=50);
    opacity: .5
}

.carousel-control.left {
    /*background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);*/
    /*background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);*/
    /*background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)));*/
    /*background-image: linear-gradient(to right, rgba(0, 0, 0, .5) 0, rgba(0, 0, 0, .0001) 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);*/
    /*background-repeat: repeat-x*/
}

.carousel-control.right {
    right: 0;
    left: auto;
    /*background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);*/
    /*background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);*/
    /*background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)));*/
    /*background-image: linear-gradient(to right, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);*/
    /*background-repeat: repeat-x*/
}

.carousel-control:focus, .carousel-control:hover {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: .9
}

.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    margin-top: -10px
}

.carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    left: 50%;
    margin-left: -10px
}

.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    right: 50%;
    margin-right: -10px
}

.carousel-control .icon-next, .carousel-control .icon-prev {
    width: 20px;
    height: 20px;
    font-family: serif;
    line-height: 1
}

.carousel-control .icon-prev:before {
    content: '\2039'
}

.carousel-control .icon-next:before {
    content: '\203a'
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none
}

.carousel-indicators li {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 7px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
}

.carousel-indicators .active {
    width: 16px;
    height: 16px;
    margin: 0 7px;
    background-color: #E21A1A;
    border: 1px solid #fff;
}

.carousel-caption {
    position: absolute;
    width: 700px;
    bottom: 10px;
    top: 10px;
    left: 137px;
    z-index: 10;
    color: #fff;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0!important;
}

.carousel-caption h4 {
    font-family: Verdana, sans-serif;
    font-weight: bold;
    font-size: 32px;
}

.carousel-caption p {
    font-family: Verdana, sans-serif;
    font-size: 18px;
}

.carousel-caption small {
    font-family: Verdana, sans-serif;
    font-size: 18px;
}

.carousel-caption a {
    font-family: Verdana, sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: white;
    display: inline-block;
    line-height: 1.50;
}

.carousel-caption a:hover {
    text-decoration: none;
}

.carousel-caption .btn {
    text-shadow: none
}

.june, .robot {
    width: 840px;
}

.june small{
    font-size: 32px;
    font-weight: bold;
}
    
.slide-uetp{
    text-shadow: 0 2px 3px rgba(0, 0, 0, 1);
}
.sovkombank {
    cursor: pointer;
}

@media (min-width: 368px) and (max-width: 991px) {
    .june {
        width: 610px;
    }
    .robot {
        width: 650px;
    }

    .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
        width: 30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px
    }

    .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
        margin-left: -10px
    }

    .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
        margin-right: -10px
    }

    .carousel-caption {
        left: 150px;
        right: 30%;
        padding-bottom: 70px
    }

    .carousel-indicators {
        bottom: 20px
    }
    
    .carousel-nr-block {
        left: 100px !important;
        width: 510px !important;
    }
}
@media (min-width: 1441px) and (max-width: 1920px) {
    .carousel-nr-block {
        left: 170px !important;
    }
}

@media (min-width: 1921px) {
    .carousel-nr-block {
        left: 200px !important;
    }
}

@media screen and (min-width: 992px) {
    .carousel-caption {
        padding-bottom: 60px
    }
}
@media screen and (max-width: 991px) {
    .carousel-caption-nr {
        font-size: 24px !important;
    }

    .carousel-text-nr {
        font-size: 12px !important;
        width: 510px !important;
    }
    .carousel-text-nr-link {
        font-size: 12px !important;
    }
}

@media (min-width: 992px) {
    .carousel-caption-nr {
        font-size: 32px !important;
    }

    .carousel-text-nr {
        font-size: 18px !important;
    }
    .carousel-text-nr-link {
        font-size: 16px !important;
    }
}

.btn-detailed-text-top {
    vertical-align: text-top;
    margin-left: 7px;
}

.btn-detailed-text-top :hover {
    opacity: 0.9;
}
.btn-detailed-dblock {
  display: block;

}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

.carousel-caption-mchd {
    font-family: Inter;
    font-size: 40px !important;
    font-weight: 700;
    padding-left: 15px;
    vertical-align: middle;
}
.carousel-btn-mchd {
    color: #543EA3 !important;
    font-family: Inter !important;
    font-weight: 500 !important;
    background: linear-gradient(180deg, #F5F2FF, #B7A4FB) !important;
}

.carousel-caption-nr span {
    font-family: Verdana, sans-serif;
}

.carousel-text-nr span {
    font-family: Verdana, sans-serif;
}

.nr-underline, .nr-underline:hover {
    text-decoration-line: underline !important;
}

